import { Auth } from 'aws-amplify';
import awsSignIn from 'services/awsSignIn';
import loadPendo from './loadPendo';

let userDetails;

export function getJwt() {
  return localStorage.getItem('jwt');
}

export function getExit() {
  return localStorage.getItem('exit');
}

export function getLocale() {
  return localStorage.getItem('locale');
}

export function getCourseLocale() {
  return localStorage.getItem('courseLocale');
}

export function getUserDetails() {
  return userDetails;
}

// When copying a course, don't want to return user to previous course's details page
export function updateExitStrategyForCopy() {
  const currentExit = getExit();
  if (currentExit.indexOf('/courses/') > 0) {
    const newExit = currentExit.substring(0, currentExit.indexOf('/courses/') + 9);
    localStorage.setItem('exit', newExit.concat('?action=draft'));
  } else if (currentExit.indexOf('/observations/') > 0) {
    const newExit = currentExit.substring(0, currentExit.indexOf('/observations/') + 14);
    localStorage.setItem('exit', newExit.concat('?action=draft'));
  }
  // use existing exit strategy
}

const getNewExitStrategy = ({ jwt, exit }) => {
  if (!jwt || jwt === getJwt()) {
    return getExit();
  }
  return exit ?? '/return';
};

function findConfig(name, configs) {
  return configs.find(({ id }) => id === name)?.value;
}

export async function authenticate({ jwt, exit }) {
  const newExit = getNewExitStrategy({ jwt, exit });
  if (jwt) {
    localStorage.setItem('jwt', jwt);
  }
  const authJwt = getJwt();
  if (!authJwt) {
    throw new Error('No token');
  }
  await Auth.signOut();
  userDetails = await awsSignIn(authJwt);

  if (findConfig('ENABLE_PENDO', userDetails.configs)) {
    const apiKey = findConfig('PENDO_KEY', userDetails.configs);
    loadPendo(apiKey, { client: userDetails.client, id: userDetails.id });
  }

  const availableContentLocales = findConfig('AVAILABLE_CONTENT_LOCALES', userDetails.configs) ?? ['en_US'];
  localStorage.setItem('availableContentLocales', availableContentLocales);
  localStorage.setItem('exit', newExit);
  return true;
}

export async function logout() {
  await Auth.signOut();
  localStorage.removeItem('jwt');
}
