import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';

const PREFIX = 'BasePage';

const classes = {
  root: `${PREFIX}-root`,
};

const Root = styled('div')({
  [`&.${classes.root}`]: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    padding: 0,
    paddingBottom: 68, // leave room for the button bar at the bottom
    backgroundColor: '#eceff1',
  },
});

export default function BasePage({ children }) {
  return <Root className={classes.root}>{children}</Root>;
}

BasePage.defaultProps = {
  children: null,
};

BasePage.propTypes = {
  children: PropTypes.node,
};
