import React from 'react';
import { useNavigate } from 'react-router-dom';
import HeaderBar from 'component/Header/HeaderBar';
import ClosePageButton from 'component/Header/ClosePageButton';

export default function SaveAndClose() {
  const navigate = useNavigate();
  return (
    <HeaderBar gutterBottom>
      <ClosePageButton
        onClick={() => {
          navigate(-1);
        }}
      />
    </HeaderBar>
  );
}
