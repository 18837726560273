import React from 'react';
import { FormattedMessage } from 'react-intl';
import ErrorOutline from '@mui/icons-material/ErrorOutline';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import HeaderBar from 'component/Header/HeaderBar';
import ClosePageButton from 'component/Header/ClosePageButton';
import closeContentBuilder from 'utils/closeContentBuilder';

export default function FatalErrorMessage() {
  return (
    <>
      <HeaderBar gutterBottom>
        <ClosePageButton
          onClick={() => {
            closeContentBuilder();
          }}
        />
      </HeaderBar>
      <Box pt={10} textAlign="center">
        <ErrorOutline style={{ fontSize: '56px' }} color="error" />
        <Typography variant="h6">
          <FormattedMessage id="errors.unableToAccessBuilder" />
        </Typography>
        <Button
          name="try-again"
          type="text"
          color="secondary"
          onClick={() => {
            window.location.reload();
          }}
        >
          <FormattedMessage id="labels.tryAgain" />
        </Button>
      </Box>
    </>
  );
}
