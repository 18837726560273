export const COURSE_CREATE_SUCCESS_CODE = 200;
export const COURSE_UPDATE_SUCCESS_CODE = 200;
export const COURSE_API_PATH = '/api/course';

export const COURSE_REFETCH_INTERVAL = 5000;
export const EXTERNAL_ID_MAX_FETCH_ATTEMPTS = 5;

export const SNACKBAR_TTL = 6000;

export const SOURCE_BUCKET_NAME = 'scb-source';
export const SOURCE_BUCKET_REGION = 'us-east-1';
export const SOURCE_BUCKET_SIGNED_URL_EXPIRATION = 10000000;
export const SOURCE_BUCKET_UPLOAD_SFFX = '_original';

export const MULTIPART_UPLOAD_THRESHOLD = 50000000; // 50 MB
export const MULTIPART_UPLOAD_CHUNK_SIZE = 10000000; // 10MB
export const MEDIA_TYPES = {
  video: 'video',
  question: 'question',
  photo: 'photo',
  text: 'text',
  document: 'document',
};

export const RASTERIZED_PHOTO_TYPES = ['jpeg', 'jpg', 'png', 'gif'];

/**
 * @see ../../api/libs/constants-lib.js
 */
export const WORD_MIME_TYPES = [
  'application/msword',
  'application/rtf',
  'text/rtf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];
export const EXCEL_MIME_TYPES = [
  'application/ms-excel',
  'application/msexcel',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];
export const DOC_ACCEPT_TYPES = ['application/pdf'];

export const AMPLIFY_CONFIG_HELPER = {
  api: {
    name: 'ApiGatewayRestApi',
    path: '/courses',
    auth: '/verify',
  },
};

export const RESPONSES = {
  SUCCESS: 'success',
  ERROR: 'error',
};

export const EDITOR_FORMAT = {
  MARKDOWN: 'markdown',
};

export const VARIANTS = {
  SUCCESS: 'success',
  ERROR: 'error',
  WARNING: 'warning',
};

export const CLOSE_ACTIONS = {
  DRAFT: 'draft',
  DRAFT_NO_PUBLISH: 'draft-no-publish',
  DRAFT_LOCKED: 'draft-locked',
  DRAFT_DELETED: 'draft-deleted',
  PUBLISH: 'publish',
  SAVE_VERSION: 'save-version',
  ERROR: 'error',
  ABANDON: 'abandon',
  COPY: 'copy',
};

export const VERSION_DEGREES = {
  PATCH: 'patch',
  MINOR: 'minor',
  MAJOR: 'major',
};

export const COURSE_GENERATION_STATUSES = {
  RUNNING: 'RUNNING',
  SUCCEEDED: 'SUCCEEDED',
  FAILED: 'FAILED',
  TIMED_OUT: 'TIMED_OUT',
  ABORTED: 'ABORTED',
};

export const VIDEO_OPTIMIZATION_STATUSES = {
  COMPLETE: 'COMPLETE',
  ERROR: 'ERROR',
  FAILED: 'FAILED',
  IN_PROGRESS: 'IN_PROGRESS',
};

export const COURSE_STATUS = {
  DRAFT: 'draft',
  LOCKED_BY_USER: 'lockedByUser',
  NEW: 'new',
  NOT_FOUND: 'notFound',
  PUBLISHED: 'published',
  ABANDONED: 'abandoned',
  ARCHIVED: 'archived',
};

export const COURSE_TYPES = {
  PLAYBOOK: 'playbook',
  OBSERVATION: 'observation',
};

export const COMPANY_TYPES = {
  COMPANY_SPECIFIC: 'company_specific',
  ALCHEMY: 'alchemy',
};

export const DEFAULT_TIMEZONE = 'America/Chicago';

export const DEFAULT_LOCALE = 'en-US';

export const AVAILABLE_LOCALES = ['en-US', 'es-US', 'fr-CA', 'en-GB', 'es-MX', 'pt-BR', 'fr-FR', 'de-DE'];

export const DOC_THUMB_EXTENSION = 'png';

export const AVAILABLE_COURSE_CREATE_LOCALES = [
  'en',
  'en-US',
  'en-GB',
  'es',
  'es-US',
  'fr',
  'fr-CA',
  'my',
  'my-MM',
  'zh',
  'zh-CN',
  'zh-HK',
  'zh-TW',
  'am',
  'az',
  'be',
  'bg',
  'bn',
  'bs',
  'ceb',
  'cs',
  'cs-CZ',
  'co',
  'da',
  'da-DK',
  'nl',
  'nl-BE',
  'nl-NL',
  'en-AU',
  'en-BZ',
  'en-CA',
  'en-HK',
  'en-IN',
  'en-IE',
  'en-MY',
  'en-NZ',
  'en-PH',
  'en-SG',
  'en-ZA',
  'en-ZW',
  'eu',
  'fil',
  'fil-PH',
  'fi',
  'fi-FI',
  'fr-BE',
  'fr-CM',
  'fr-FR',
  'fr-CI',
  'fr-LU',
  'fr-ML',
  'fr-MC',
  'fr-MA',
  'fr-SN',
  'fr-CH',
  'fy',
  'ka',
  'ka-GE',
  'de',
  'de-AT',
  'de-DE',
  'de-LI',
  'de-LU',
  'de-CH',
  'el',
  'el-GR',
  'gd',
  'gl',
  'gu',
  'gu-IN',
  'ha',
  'haw',
  'hmn',
  'ht',
  'ht-HT',
  'hi',
  'hi-IN',
  'hu',
  'hu-HU',
  'hy',
  'id',
  'id-ID',
  'ig',
  'is',
  'is-IS',
  'it',
  'it-IT',
  'it-CH',
  'ja',
  'ja-JP',
  'jw',
  'km',
  'km-KH',
  'kn',
  'ko',
  'ko-KR',
  'ky',
  'la',
  'lb',
  'lo',
  'lt',
  'lv',
  'mg',
  'mi',
  'mk',
  'ml',
  'mn',
  'mr',
  'ms',
  'mt',
  'ne',
  'ne-IN',
  'ne-NP',
  'no',
  'ny',
  'pl',
  'pl-PL',
  'pt',
  'pt-AO',
  'pt-BR',
  'pt-PT',
  'pa',
  'pa-IN',
  'ro',
  'ro-RO',
  'ru',
  'ru-RU',
  'si',
  'sm',
  'sn',
  'so',
  'so-SO',
  'sr',
  'st',
  'su',
  'sw',
  'es-AR',
  'es-BO',
  'es-CL',
  'es-CO',
  'es-CR',
  'es-DO',
  'es-EC',
  'es-SV',
  'es-GT',
  'es-HN',
  'es-419',
  'es-MX',
  'es-NI',
  'es-PA',
  'es-PY',
  'es-PE',
  'es-PR',
  'es-ES',
  'es-UY',
  'ta',
  'te',
  'tg',
  'tl',
  'th',
  'th-TH',
  'ti',
  'ti-ER',
  'ti-ET',
  'tr',
  'tr-TR',
  'uk',
  'uk-UA',
  'uz',
  'vi',
  'vi-VN',
  'xh',
  'yo',
  'zu',
  'ar',
  'ar-DZ',
  'ar-BH',
  'ar-EG',
  'ar-IQ',
  'ar-JO',
  'ar-KW',
  'ar-LB',
  'ar-LY',
  'ar-MA',
  'ar-OM',
  'ar-QA',
  'ar-SA',
  'ar-SY',
  'ar-TN',
  'ar-AE',
  'ar-YE',
];

export const SCAN_STATUS = {
  DISABLED: 'DISABLED',
  INITIALIZING: 'INITIALIZING',
  UPLOADING: 'UPLOADING',
  SCANNING: 'SCANNING',
  ERROR: 'ERROR',
  SCANNED_CLEAN: 'clean',
  SCANNED_MALICIOUS: 'malicious',
  IN_PROGRESS: 'in_progress',
  COPYING: 'COPYING',
};

export const OBSERVATION_QUESTION_TYPE = {
  COMPLIANCE: 'compliance',
  PARTIAL_COMPLIANCE: 'partialCompliance',
  SAFE_AT_RISK: 'safeRisk',
  SCALED_SCORE: 'scaledScore',
  YES_NO: 'yesNo',
};

export const COURSE_SORT_OPTIONS = {
  DATE_DESCENDING: 'dateDesc',
  DATE_ASCENDING: 'dateAsc',
  TITLE_DESCENDING: 'nameDesc',
  TITLE_ASCENDING: 'nameAsc',
};
