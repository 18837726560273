import React from 'react';
import PropTypes from 'prop-types';
import MuiTypography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

const Typography = styled(MuiTypography, { label: 'HeaderTitle' })(({ theme: { palette, spacing } }) => ({
  fontSize: spacing(2),
  fontWeight: 500,
  flexGrow: 1,
  WebkitLineClamp: 1,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  '@media print': {
    color: palette.common.black,
  },
}));

export default function HeaderTitle({ children, ...props }) {
  return (
    <Typography {...props} variant="h6">
      {children}
    </Typography>
  );
}

HeaderTitle.defaultProps = {
  children: null,
};

HeaderTitle.propTypes = {
  children: PropTypes.node,
};
