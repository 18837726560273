export default function loadPendo(apiKey, { client, id }) {
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://cdn.pendo.io/agent/static/${apiKey}/pendo.js`;
  const [z] = document.getElementsByTagName('script');
  z.parentNode.insertBefore(script, z);
  script.onload = () => {
    window.pendo.initialize({
      visitor: {
        id: `${client}_${id}`,
      },
      account: {
        id: client,
      },
    });
  };
}
