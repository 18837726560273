import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import { blue } from '@mui/material/colors';
import Box from '@mui/material/Box';
import MuiCircularProgress from '@mui/material/CircularProgress';

const CircularProgress = styled(MuiCircularProgress, { label: 'CircularProgress' })(() => ({
  color: blue[700],
}));

const Label = styled('div', { label: 'CircularProgressLabel' })(({ theme: { spacing } }) => ({
  margin: spacing(0, 0, 1),
}));
export default function CircularProgressLoader({ message }) {
  return (
    <Box alignItems="center" display="flex" flexDirection="column" m={2}>
      <Label>{message}</Label>
      <CircularProgress size={35.7} />
    </Box>
  );
}

CircularProgressLoader.defaultProps = {
  message: <FormattedMessage id="labels.loading" />,
};

CircularProgressLoader.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
