import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled } from '@mui/material/styles';

const PREFIX = 'HeaderBar';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  toolbarHidden: `${PREFIX}-toolbarHidden`,
};

const Root = styled('div', { shouldForwardProp: (prop) => !['twoTone', 'gutterBottom'].includes(prop) })(
  ({ theme, twoTone, gutterBottom }) => ({
    [`& .${classes.root}`]: {
      background: twoTone
        ? 'linear-gradient(#474e54, #474e54 42px, #ffc700 42px)'
        : 'linear-gradient(#474e54, #474e54 42px)',
      boxShadow: twoTone ? '0 2px 4px 0 rgba(0, 0, 0, 0.5)' : 'none',
    },
    [`& .${classes.toolbar}`]: {
      minHeight: 50,
    },
    [`& .${classes.toolbarHidden}`]: {
      minHeight: 50,
      marginBottom: gutterBottom ? theme.spacing(2) : null,
    },
  })
);

export default function HeaderBar({ children, ...props }) {
  return (
    <Root {...props}>
      <AppBar position="fixed" className={classes.root}>
        <Toolbar className={classes.toolbar}>{children}</Toolbar>
      </AppBar>
      {/**
       * Extra Toolbar makes sure the content starts after the AppBar and not under it.
       * Also gives an anchor to link to to go back to the top of the page.
       */}
      <Toolbar id="back-to-top-anchor" className={classes.toolbarHidden} />
    </Root>
  );
}

HeaderBar.defaultProps = {
  children: null,
  gutterBottom: false,
  twoTone: true,
};

HeaderBar.propTypes = {
  children: PropTypes.node,
  gutterBottom: PropTypes.bool,
  twoTone: PropTypes.bool,
};
