import React from 'react';
import Grid from '@mui/material/Grid';
import CircularProgressLoader from 'assets/components/loaders/CircularProgressLoader';

export default function Loading() {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={8}>
        <CircularProgressLoader />
      </Grid>
    </Grid>
  );
}
