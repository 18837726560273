import { useEffect } from 'react';
import { useIntl } from 'react-intl';

export default function useTitle(title) {
  const { formatMessage } = useIntl();
  const origTitle = `${formatMessage({ id: 'appTitle' })} | Alchemy Manager`;

  useEffect(() => {
    document.title = title ? `${title} | ${origTitle}` : origTitle;
  }, [title, origTitle]);

  useEffect(
    () => () => {
      document.title = origTitle;
    },
    [origTitle]
  );
}
