import React, { useState, useEffect } from 'react';
import Amplify from 'aws-amplify';
import { QueryClient, QueryClientProvider, useQueryErrorResetBoundary } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import flattenMessages from 'utils/flatten-messages';
import getLocaleData from 'services/getLocaleData';
import { assign } from 'utils/locationUtils';
import theme from 'config/theme';
import { ErrorBoundary } from 'react-error-boundary';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './index.css';
import { useIdleTimer } from 'react-idle-timer';
import { logout, getLocale, getCourseLocale } from 'utils/auth';
import { DEFAULT_TIMEZONE, DEFAULT_LOCALE, AVAILABLE_LOCALES, AVAILABLE_COURSE_CREATE_LOCALES } from 'utils/constants';
import formats from 'config/intl/formats';
import queryString from 'query-string';
import FatalErrorMessage from 'component/FatalErrorMessage';
import { messages as advSelMessages } from '@alchemyeng/advanced-selector';
import BasePage from 'component/BasePage';
import LoadingPage from 'component/LoadingPage/index.component';
import loadConfig from 'config/index';
import CustomSnackBarProvider from 'CustomSnackbarProvider';
import Routes from './Routes.component';

const queryClient = new QueryClient();

const queryLocale = queryString.parseUrl(window.location.href).query.locale?.replace(/_/g, '-');
const queryCourseLocale = queryString.parseUrl(window.location.href).query.courseLocale?.replace(/_/g, '-');

const storedLocale = getLocale() || DEFAULT_LOCALE;
const storedCourseLocale = getCourseLocale() || DEFAULT_LOCALE;

const localeTarget = !queryLocale || queryLocale === storedLocale ? storedLocale : queryLocale;
const courseLocaleTarget =
  !queryCourseLocale || queryCourseLocale === storedCourseLocale ? storedCourseLocale : queryCourseLocale;

const locale = AVAILABLE_LOCALES.includes(localeTarget) ? localeTarget : DEFAULT_LOCALE;
const courseLocale = AVAILABLE_COURSE_CREATE_LOCALES.includes(courseLocaleTarget) ? courseLocaleTarget : DEFAULT_LOCALE;

localStorage.setItem('locale', locale);
localStorage.setItem('courseLocale', courseLocale);

// Replace default timezone with user store object value when its implemented
const timeZone = DEFAULT_TIMEZONE;
if ('__setDefaultTimeZone' in Intl.DateTimeFormat) {
  Intl.DateTimeFormat.__setDefaultTimeZone(DEFAULT_TIMEZONE); /* eslint-disable-line no-underscore-dangle */
}

export default function App() {
  const { reset } = useQueryErrorResetBoundary();
  const [messages, setMessages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (Object.keys(messages).length === 0) {
      getLocaleData(locale).then((data) => {
        setMessages({ ...flattenMessages(data.messages), ...advSelMessages(locale) });
      });
    }
  }, [messages]);

  useEffect(() => {
    loadConfig().then(async () => {
      const { default: amplifyConf } = await import('./config/amplify');
      Amplify.configure(amplifyConf);
      setIsLoading(false);
    });
  }, [isLoading, setIsLoading]);

  useIdleTimer({
    timeout: 3600000,
    debounce: 250,
    stopOnIdle: true,
    onIdle: () => {
      logout();
      assign(localStorage.getItem('exit'));
    },
  });

  if (!Object.keys(messages).length) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <IntlProvider
        messages={messages}
        key={locale}
        locale={locale}
        defaultLocale={DEFAULT_LOCALE}
        timeZone={timeZone}
        formats={formats}
      >
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <CustomSnackBarProvider>
              <ErrorBoundary onReset={reset} fallbackRender={FatalErrorMessage}>
                <BasePage>
                  <BrowserRouter>{isLoading ? <LoadingPage /> : <Routes />}</BrowserRouter>
                </BasePage>
              </ErrorBoundary>
            </CustomSnackBarProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </IntlProvider>
    </QueryClientProvider>
  );
}
