import axios from 'axios';

const loadConfig = async () => {
  if (window?.env?.REACT_APP_SERVICE_ENDPOINT) {
    // already loaded good config, return from cahce
    window.env.DBG_CONFIG_RTN = 'cache';
    return window.env;
  }

  // decide if we need to pull dynamic config from api
  let dynamicConfig = { DBG_CONFIG_SRC: 'process.env' };
  if (!process.env?.REACT_APP_SERVICE_ENDPOINT) {
    // loaded config is no good, we'll try load dynamically
    ({ data: dynamicConfig } = await axios.get('/env.json'));
    dynamicConfig.DBG_CONFIG_SRC = '/env.json';
  }

  // cache it and make it available to entire app
  window.env = {
    ...process.env, // allow to still use .env config
    ...dynamicConfig, // override with dynamically loaded config
    DBG_CONFIG_RTN: 'fresh',
  };
  return window.env;
};
export default loadConfig;
