import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'react-intl';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function ClosePageButton({ onClick, icon }) {
  const intl = useIntl();
  return (
    <IconButton
      edge="start"
      color="inherit"
      onClick={onClick}
      aria-label={intl.formatMessage({ id: 'labels.close' })}
      data-testid="CloseButton"
      name="close"
      size="large"
    >
      {icon}
    </IconButton>
  );
}

ClosePageButton.defaultProps = {
  onClick: null,
  icon: <CloseIcon />,
};

ClosePageButton.propTypes = {
  onClick: PropTypes.func,
  icon: PropTypes.node,
};
