import React, { Suspense } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import useQueryParams from 'hooks/useQueryParams';
import { authenticate } from 'utils/auth';
import { useQuery } from 'react-query';

import Unauthorized from 'pages/Unauthorized/Unauthorized.component';
import NotFound from 'pages/NotFound/NotFound.component';
import SaveAndClose from 'pages/Course/SaveAndClose/index.component';
import LoadingPage from 'component/LoadingPage/index.component';
import useTitle from 'hooks/useTitle';

const Observations = React.lazy(() => import('pages/Observations/App'));
const CourseApp = React.lazy(() => import('pages/Course/App'));

function useAuthentication() {
  const { jwt, exit, ...params } = useQueryParams();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  return useQuery(['auth'], () => authenticate({ jwt, exit }), {
    onSuccess() {
      if (jwt || exit) {
        navigate({ pathname, search: queryString.stringify(params) }, { replace: true, state });
      }
    },
    refetchOnWindowFocus: false,
  });
}

export default function RoutesComponent() {
  const { pathname } = useLocation();
  const { isSuccess, isLoading } = useAuthentication();

  // needed to force the page title to get updated with the localized text
  useTitle();

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!isSuccess) {
    return <Unauthorized />;
  }

  if (pathname === '/') {
    return <Navigate to="/course" />;
  }

  return (
    <Suspense fallback={<LoadingPage />}>
      <Routes>
        <Route path="/course/*" element={<CourseApp />} />
        <Route path="/observations/*" element={<Observations />} />
        <Route path="/return" element={<SaveAndClose />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
}
