import { API, Auth } from 'aws-amplify';
import { AMPLIFY_CONFIG_HELPER } from 'utils/constants';

export default async (jwt) => {
  const bearerHeader = `Bearer ${jwt}`;
  const { default: amplifyConf } = await import('config/amplify');
  const request = {
    headers: { Authorization: bearerHeader },
    body: { identityPoolId: amplifyConf.Auth.identityPoolId },
  };

  const verifyResponse = await API.post(AMPLIFY_CONFIG_HELPER.api.name, AMPLIFY_CONFIG_HELPER.api.auth, request);

  await Auth.federatedSignIn('developer', {
    token: verifyResponse.developer.Token,
    identity_id: verifyResponse.developer.IdentityId,
  });

  return Promise.resolve({
    ...verifyResponse,
    jwt,
  });
};
