import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { SnackbarProvider } from 'notistack';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';

const PREFIX = 'SnackBarRoot';

const classes = {
  containerRoot: `${PREFIX}-containerRoot`,
  variantWarning: `${PREFIX}-variantWarning`,
};

const Root = styled('div', { label: 'SnackBarRoot' })(({ theme: { spacing } }) => ({
  [`& .${classes.containerRoot}`]: {
    bottom: spacing(10.5),
    width: 'max-content',
    '@media (max-width: 960px)': {
      maxWidth: '90%',
    },
  },
  [`& .${classes.variantWarning}`]: {
    flexWrap: 'inherit',
  },
}));

const CloseButton = styled(IconButton, { label: 'SnackbarCloseButton' })(({ theme: { palette } }) => ({
  height: '100%',
  left: 0,
  top: 0,
  width: '100%',
  color: palette.common.white,
}));

// Add action to all snackbars.
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

export default function CustomSnackBarProvider({ children }) {
  const { containerRoot, variantWarning } = classes;
  const closeActionIcon = useCallback(
    (key) => (
      <CloseButton onClick={onClickDismiss(key)} name="close" size="large">
        <CloseIcon />
      </CloseButton>
    ),
    []
  );

  return (
    <Root>
      <SnackbarProvider
        ref={notistackRef}
        iconVariant={{ error: <ErrorIcon style={{ marginRight: '10px' }} /> }}
        action={closeActionIcon}
        maxSnack={1}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        classes={{ containerRoot, variantWarning }}
        dense
      >
        {children}
      </SnackbarProvider>
    </Root>
  );
}

CustomSnackBarProvider.defaultProps = {
  children: null,
};

CustomSnackBarProvider.propTypes = {
  children: PropTypes.node,
};
