const formats = {
  date: {
    year: { year: 'numeric' },
    month: { month: 'numeric' },
    yearMonth: {
      year: 'numeric',
      month: 'numeric',
    },
    date: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    dateTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    dateTimeTZ: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
  },
};

export default formats;
