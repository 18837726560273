import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import HeaderBar from 'component/Header/HeaderBar';
import ClosePageButton from 'component/Header/ClosePageButton';
import HeaderTitle from 'component/Header/HeaderTitle';
import useTitle from 'hooks/useTitle';

export default function NotFound() {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  useTitle(formatMessage({ id: 'errors.error' }));

  return (
    <>
      <HeaderBar gutterBottom>
        <ClosePageButton
          onClick={() => {
            navigate(-1);
          }}
        />
        <HeaderTitle>
          <FormattedMessage id="errors.error" />
        </HeaderTitle>
      </HeaderBar>
      <Box pt={10} textAlign="center">
        <Typography variant="h6">
          <FormattedMessage id="errors.error" />
        </Typography>
      </Box>
    </>
  );
}
