export default function getLocaleData(locale) {
  const requests = [];
  /* eslint-disable prefer-template */
  requests.push(import('config/intl/' + locale + '/main.json'));
  /* eslint-enable prefer-template */

  if (Intl.DateTimeFormat.polyfilled) {
    switch (locale) {
      case 'de-DE':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/de'));
        break;

      case 'en-GB':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/en-GB'));
        break;

      case 'es-ES':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/es'));
        break;
      case 'es-MX':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/es-MX'));
        break;
      case 'es-US':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/es-US'));
        break;

      case 'fr-CA':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/fr-CA'));
        break;
      case 'fr-FR':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/fr'));
        break;

      case 'pt-BR':
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/pt'));
        break;

      case 'en-US':
      default:
        requests.push(import('@formatjs/intl-datetimeformat/locale-data/en'));
        break;
    }
  }

  if (Intl.PluralRules.polyfilled) {
    switch (locale) {
      case 'de-DE':
        requests.push(import('@formatjs/intl-pluralrules/locale-data/de'));
        break;

      case 'es-ES':
      case 'es-MX':
      case 'es-US':
        requests.push(import('@formatjs/intl-pluralrules/locale-data/es'));
        break;

      case 'fr-CA':
      case 'fr-FR':
        requests.push(import('@formatjs/intl-pluralrules/locale-data/fr'));
        break;

      case 'pt-BR':
        requests.push(import('@formatjs/intl-pluralrules/locale-data/pt'));
        break;

      case 'en-US':
      case 'en-GB':
      default:
        requests.push(import('@formatjs/intl-pluralrules/locale-data/en'));
        break;
    }
  }

  return Promise.all(requests).then(([messages]) => messages);
}
