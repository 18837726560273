import React from 'react';
import { useNavigate } from 'react-router-dom';
import ClosePageButton from 'component/Header/ClosePageButton';
import HeaderBar from 'component/Header/HeaderBar';
import Loading from 'component/Loading';

export default function LoadingPage() {
  const navigate = useNavigate();

  return (
    <>
      <HeaderBar>
        <ClosePageButton
          onClick={() => {
            navigate(-1);
          }}
        />
      </HeaderBar>
      <Loading />
    </>
  );
}
