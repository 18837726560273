import React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import useTitle from 'hooks/useTitle';

const PREFIX = 'Unauthorized';

const classes = {
  paper: `${PREFIX}-paper`,
};

const Root = styled('div', { label: 'Unauthorized' })(({ theme: { spacing, palette } }) => ({
  flexGrow: 1,
  [`& .${classes.paper}`]: {
    padding: spacing(2),
    textAlign: 'center',
    color: palette.text.secondary,
  },
}));

export default function Unauthorized() {
  const { formatMessage } = useIntl();
  useTitle(formatMessage({ id: 'pages.unauthorized.unauthorized' }));

  return (
    <Root>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={classes.paper}>
            <FormattedMessage id="pages.unauthorized.unauthorized" />
          </Paper>
        </Grid>
      </Grid>
    </Root>
  );
}
