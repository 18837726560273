import QueryString from 'query-string';
import { assign } from 'utils/locationUtils';
import { getExit, getCourseLocale } from './auth';
import { CLOSE_ACTIONS, DEFAULT_LOCALE } from './constants';

export default async (action, lockedByUser = null) => {
  const exit = getExit();
  // Before exiting, reset courseLocale to the default if it's not set
  // to something other than the default.  This is a precaution
  // in case the calling app (e.g. Playbook) doesn't provide courseLocale
  // in the query string params
  if (getCourseLocale() !== DEFAULT_LOCALE) {
    localStorage.setItem('courseLocale', DEFAULT_LOCALE);
  }
  if (action) {
    const parsedUrl = QueryString.parseUrl(exit);
    parsedUrl.query.action = action;
    if (action === CLOSE_ACTIONS.DRAFT_LOCKED && lockedByUser) {
      parsedUrl.query.lockedByFirstName = lockedByUser.first_name;
      parsedUrl.query.lockedByLastName = lockedByUser.last_name;
      parsedUrl.query.lockedByUsername = lockedByUser.username;
    }
    assign(QueryString.stringifyUrl(parsedUrl));
  } else {
    assign(exit);
  }
};
